import { Component, OnInit, Inject } from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';

@Component({
  selector: 'app-custom-snackbar',
  templateUrl: './custom-snackbar.component.html',
  styleUrls: ['./custom-snackbar.component.scss']
})
export class CustomSnackbarComponent implements OnInit {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any,
              private SnackBarRef: MatSnackBarRef<CustomSnackbarComponent>) { }

  ngOnInit() {
  }

  // Dismiss Snackbar
  closeSnackbar() {
     this.SnackBarRef.dismiss();
  }

}
