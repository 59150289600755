import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {HttpParams} from '@angular/common/http';

class HelperClass {
  markAllFormFieldsAsTouched(formGroup: FormGroup) {
    formGroup.markAsTouched({onlySelf: true});
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({onlySelf: true});
        // control.markAsDirty({onlySelf: true});
        control.updateValueAndValidity({onlySelf: true, emitEvent: true});
      } else if (control instanceof FormGroup) {
        control.markAsTouched({onlySelf: true});
        this.markAllFormFieldsAsTouched(control);
      } else if (control instanceof FormArray) {
        control.markAsTouched({onlySelf: true});
        for (const ctrl of control.controls) {
          if (ctrl instanceof FormGroup) {
           this.markAllFormFieldsAsTouched(ctrl);
          } else if (ctrl instanceof FormControl) {
            control.markAsTouched({onlySelf: true});
            control.updateValueAndValidity({onlySelf: true, emitEvent: true});
          }
        }
      }
    });
  }

  setQueryParams(parameters: object, acceptedParams: Array<string>, httpParams?: HttpParams) {
    let params = httpParams || new HttpParams();
    acceptedParams.forEach(param => {
      params = parameters[param] ? params.append(param, parameters[param]) : params;
    });
    return params;
  }

  downloadBlobResponse(response: any, fileType: string, pdfName?: string, newTab?: boolean) {
    const a = document.createElement('a');
    const blob = new Blob([response], {type: fileType});
    const url = window.URL.createObjectURL(blob);
    if (newTab) {
      window.open(url, '_blank');
    } else {
      a.href = url;
      a.download = pdfName;
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 100);
    }
  }

}

export const Helpers = new HelperClass();
