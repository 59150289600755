<div class="custom-mat-snackbar">
    <div fxLayout="column">
        <div fxLayout="row wrap">
            <div fxFlex="15">
                <mat-icon [ngClass]="data.icon_color">{{data.icon_type}}</mat-icon>
            </div>
            <div fxFlex="85">
                <span class="white-color" [innerHTML]="data.message"></span>
            </div>
        </div>
    </div>
</div>
