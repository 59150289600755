<mat-dialog-content class="modal-header">
  <h2 class="my-12 ml-4 white-color">Add a new user</h2>
</mat-dialog-content>
<mat-dialog-content class="py-16">
  <form [formGroup]="userForm">
    <div class="mt-16" fxLayout="column">
      <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="space-between">
        <mat-form-field fxFlex="48" fxFlex.lt-md="100" appearance="outline">
          <mat-label>Email</mat-label>
          <input matInput formControlName="email">
          <mat-error *ngIf="userForm.get('email').hasError('required') && userForm.get('email').touched">
            This field is required
          </mat-error>
          <mat-error *ngIf="userForm.get('email').hasError('email') && userForm.get('email').touched">
            Invalid Email Address
          </mat-error>
          <mat-error *ngIf="userForm.get('email').hasError('emailExist') && !userForm.get('email').hasError('email') && userForm.get('email').touched">
             Email Address already exists.
          </mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="48" fxFlex.lt-md="100" appearance="outline">
          <mat-label>Role</mat-label>
          <mat-select formControlName="role_id">
            <mat-option [value]="1">Admin</mat-option>
            <mat-option [value]="2">Moderator</mat-option>
          </mat-select>
          <mat-error *ngIf="userForm.get('role_id').hasError('required') && userForm.get('role_id').touched">
            This field is required
          </mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="48" fxFlex.lt-md="100" appearance="outline">
          <mat-label>Name</mat-label>
          <input matInput formControlName="name">
          <mat-error *ngIf="userForm.get('name').hasError('required') && userForm.get('name').touched">
            This field is required
          </mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="48" fxFlex.lt-md="100" appearance="outline">
          <mat-label>Phone</mat-label>
          <input matInput formControlName="phone">
          <mat-error *ngIf="userForm.get('phone').hasError('required') && userForm.get('phone').touched">
            This field is required
          </mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="48" fxFlex.lt-md="100" appearance="outline">
          <mat-label>Designation</mat-label>
          <input matInput formControlName="designation">
          <mat-error *ngIf="userForm.get('designation').hasError('required') && userForm.get('designation').touched">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions ngClass.gt-xs="pull-right" fxLayout.xs="column-reverse">
  <button type="button" mat-stroked-button class="primary-color"
          (click)="closeDialog()" fxFlex.lt-md="100" ngClass.xs="ml-0 full-width">Cancel
  </button>
  <button type="submit" mat-raised-button color="primary" (click)="onSubmit()"
          ngClass.xs="ml-0 my-20 full-width" fxFlex.lt-md="100" >
    {{data.type === 'add' ? 'Add User' : 'Update'}}</button>
</mat-dialog-actions>

