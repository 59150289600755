import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SeedService} from '../../service/seed.service';
import {debounceTime, filter, switchMap, takeUntil} from 'rxjs/operators';
import {
  Customer,
  CustomerInfo,
  Distribution,
  DistributionInfo,
  GeneBank
} from '../../../../shared/models/seeds';
import {forkJoin, noop, Observable, Subject} from 'rxjs';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {Helpers} from '../../../../shared/helpers/helpers';
import {MatOptionSelectionChange} from '@angular/material/core';
import {CustomValidators} from '../../../../shared/helpers/validators';
import {MatSelectChange} from '@angular/material/select';

@Component({
  selector: 'app-distribution-modal',
  templateUrl: './distribution-modal.component.html',
  styleUrls: ['./distribution-modal.component.scss']
})
export class DistributionModalComponent implements OnInit, OnDestroy {
  customerForm: FormGroup;
  distributionForm: FormGroup;
  customerId: number;
  customerIdOptions: Observable<CustomerInfo[]>;
  nameOptions: Observable<CustomerInfo[]>;
  designationOptions: Observable<CustomerInfo[]>;
  organisationOptions: Observable<CustomerInfo[]>;
  countryOptions: Observable<CustomerInfo[]>;
  contactOptions: Observable<CustomerInfo[]>;
  emailOptions: Observable<CustomerInfo[]>;
  statusOptions: Observable<CustomerInfo[]>;
  addressOptions: Observable<CustomerInfo[]>;
  purposeOptions: Observable<DistributionInfo[]>;
  locationList: Array<string>;
  selectedCustomer: CustomerInfo;
  geneBank: GeneBank;
  customerList = ['cust_id', 'name', 'designation', 'organisation', 'country', 'contact_number', 'email', 'status', 'address'];
  packageType = [{
    type: 'active_collection',
    label: 'Active',
    base: false,
    weight: undefined
  },
    {
      type: 'duplicate',
      label: 'Duplicate',
      base: false,
      weight: undefined
    },
    {
      type: 'characterization',
      label: 'Characterization',
      base: false,
      weight: undefined
    },
    {
      type: 'germination',
      label: 'Germination & Viability',
      base: true,
      weight: undefined
    },
    {
      type: 'regeneration',
      label: 'Multiplication & Regeneration',
      base: true,
      weight: undefined
    },
    {
      type: 'rest',
      label: 'Rest',
      base: true,
      weight: undefined
    }];

  private unsubscribe: Subject<void> = new Subject();

  constructor(private dialogRef: MatDialogRef<DistributionModalComponent>, private fb: FormBuilder,
              private seedService: SeedService, @Inject(MAT_DIALOG_DATA) public data: {
      seedId: number; geneBank?: GeneBank, distribution?: DistributionInfo
    }) {
  }

  ngOnInit(): void {
    this.buildCustomerForm();
    this.buildDistributionForm();
    this.customerList.forEach(control => this.checkForCustomerInfo(control));
    this.purposeOptions = this.distributionForm.get('purpose').valueChanges.pipe(debounceTime(150), filter(value => !!value),
      switchMap(value => this.seedService.fetchCommonInfo('distribution_infos', true, 'purpose', value)));
    if (this.isUpdate()) {
      forkJoin([this.seedService.getDistributionById(this.data.distribution.id),
        this.seedService.getCustomerById(this.data.distribution.customer_id),
        this.seedService.seedDetails(this.data.seedId, this.data.distribution.seed_type)])
        .subscribe(([distribution, customer, seed]) => {
          this.updateCustomerInfo(customer);
          this.geneBank = seed.gene_bank;
          this.updateDistributionForm(distribution);
          this.assignPackageWeight();
        });
    } else {
      this.geneBank = this.data.geneBank;
      this.assignPackageWeight();
    }
  }

  buildCustomerForm() {
    this.customerForm = this.fb.group({
      existing: [false],
      cust_id: [undefined],
      name: [undefined, Validators.required],
      designation: undefined,
      organisation: undefined,
      country: undefined,
      contact_number: undefined,
      email: [undefined, Validators.email],
      status: undefined,
      address: undefined
    });
  }

  buildDistributionForm() {
    this.distributionForm = this.fb.group({
      id: undefined,
      customer_id: undefined,
      seed_id: this.data.seedId,
      requested_date: [undefined, Validators.required],
      supplied_date: [undefined, Validators.required],
      package_type: [undefined, Validators.required],
      quantity: [undefined, Validators.required],
      purpose: [undefined, Validators.required],
      remarks: undefined,
      location: [undefined, Validators.required]
    });
  }

  onTypeChange(event: MatSelectChange) {
    this.setQuantityValidator();
    this.setLocations(event.value);
  }

  setQuantityValidator() {
    this.distributionForm.get('quantity').setValidators(Validators.compose([Validators.required,
      CustomValidators.quantityValidator(+this.getPackageWeight()?.weight)]));
  }

  getPackageWeight() {
    return this.packageType.find(pack => pack.type === this.distributionForm.get('package_type').value);
  }

  updateCustomerInfo(customer: CustomerInfo) {
    this.selectedCustomer = customer;
    this.customerForm.get('existing').setValue(true);
    this.customerForm.get('cust_id').setValue(customer);
  }

  updateDistributionForm(distribution: DistributionInfo) {
    this.distributionForm.patchValue({...distribution});
    this.setLocations(distribution.package_type);
  }

  closeDialog(value: boolean) {
    this.dialogRef.close(value);
  }

  checkExisting(event: MatCheckboxChange) {
    this.selectedCustomer = !event.checked ? null : this.selectedCustomer;
    this.customer('cust_id').setValidators(event.checked ? Validators.required : null);
    this.customer('name').setValidators(event.checked ? null : Validators.required);
  }

  checkForCustomerInfo(name: string) {
    this[Distribution[name]] = this.customer(name).valueChanges.pipe(debounceTime(300), takeUntil(this.unsubscribe),
      switchMap(value => this.seedService.fetchCustomerInfo('customers/auto_complete', name, value)));
  }

  onSubmit() {
    Helpers.markAllFormFieldsAsTouched(this.customerForm);
    Helpers.markAllFormFieldsAsTouched(this.distributionForm);
    if ((this.selectedCustomer || this.customerForm.valid) && this.distributionForm.valid) {
      if (!this.selectedCustomer) {
        this.seedService.createCustomer(this.customerForm.value).pipe(debounceTime(200),
          takeUntil(this.unsubscribe)).subscribe((customer: Customer) => {
          this.customerId = customer.id;
          this.distributionForm.get('customer_id').setValue(this.customerId);
          this.createDistributionInfo();
        });
      } else {
        this.distributionForm.get('customer_id').setValue(this.selectedCustomer.id);
        this.createDistributionInfo();
      }
    }
  }

  createDistributionInfo() {
    this.seedService.createDistribution(this.distributionForm.value).subscribe(response => {
      this.closeDialog(true);
    }, (error) => !this.selectedCustomer ? this.seedService.deleteCustomer(this.customerId) : noop());
  }

  customer(name: string): FormGroup {
    return this.customerForm.get(`${name}`) as FormGroup;
  }

  displayCustomerID(customer: CustomerInfo): string {
    return customer ? customer.cust_id : undefined;
  }

  customerSelect(event: MatOptionSelectionChange) {
    this.selectedCustomer = event.source.value;
  }

  assignPackageWeight() {
    this.packageType.forEach(pack => {
      pack.weight = pack.base ? this.geneBank.base_collection[pack.type + '_weight'] : this.geneBank[pack.type].weight;
    });
    this.setQuantityValidator();
  }

  checkPackWeight(weight: number): boolean {
    return +weight > 10;
  }

  setLocations(type?: string) {
     if (['active_collection', 'characterization'].includes(type)) {
       this.locationList = [this.geneBank[type].location.name];
     } else {
       this.locationList = this.geneBank[type === 'duplicate' ? 'duplicate' : 'base_collection'].locations.map(location => location.name);
     }
  }

  distributionAvailable(): boolean {
    return this.packageType.some(pack => pack.weight > 10);
  }

  isUpdate(): boolean {
    return this.data.distribution && !!this.data.distribution.id;
  }

  get today(): Date {
    return new Date();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
