<mat-dialog-content class="modal-header">
  <h2 class="mt-0 mb-4 white-color">{{ modalContent.header}}</h2>
</mat-dialog-content>
<mat-dialog-content class="py-16">
  <p class="font-size-16 boulder-color" [innerHTML]="modalContent.desc"></p>
</mat-dialog-content>
<div>
  <mat-dialog-actions ngClass.gt-xs="pull-right" fxLayout.xs="column-reverse" fxLayoutGap="12px">
    <button type="button" fxFlex.lt-md="100" ngClass.xs="ml-0 full-width" mat-stroked-button
            (click)="closeDialog(false)">
      {{ modalContent.cancelBtn}}
    </button>
    <button type="button" mat-raised-button [color]="modalContent.actionBtnColor"
            ngClass.xs="ml-0 my-20 full-width" fxFlex.lt-md="100" (click)="closeDialog(true)">
      {{ modalContent.actionBtn}}
    </button>
  </mat-dialog-actions>
</div>
