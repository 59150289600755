<div fxLayout="row wrap" fxLayoutAlign="center center" fxFlexFill>
  <div class="mat-elevation-z8 white-bg my-40" fxLayout="row wrap" fxFlex="50" fxFlex.lt-md="100" fxLayoutAlign="start center">
    <div fxFlex="15" fxFlex.lt-md="30" class="primary-bg p-12 text-center">
      <mat-icon class="white-color font-size-32">person</mat-icon>
    </div>
    <div fxFlex="85" fxFlex.lt-md="70" class="pl-12">
      <p class="font-size-16">{{userData?.name}}</p>
    </div>
    <div fxFlex="15" fxFlex.lt-md="30" class="primary-bg p-12 text-center">
      <mat-icon class="white-color font-size-32">alternate_email</mat-icon>
    </div>
    <div fxFlex="85" fxFlex.lt-md="70" class="pl-12">
      <p class="font-size-16">{{userData?.email}}</p>
    </div>
    <div fxFlex="15" fxFlex.lt-md="30" class="primary-bg p-12 text-center">
      <mat-icon class="white-color font-size-32">call</mat-icon>
    </div>
    <div fxFlex="85" fxFlex.lt-md="70" class="pl-12">
      <p class="font-size-16">{{userData?.phone}}</p>
    </div>
    <div fxFlex="15" fxFlex.lt-md="30" class="primary-bg p-12 text-center">
      <mat-icon class="white-color font-size-32">star</mat-icon>
    </div>
    <div fxFlex="85" fxFlex.lt-md="70" class="pl-12">
      <p class="font-size-16">{{userData?.designation}}</p>
    </div>
    <div fxFlex="15" fxFlex.lt-md="30" class="primary-bg p-12 text-center">
      <mat-icon class="white-color font-size-32">visibility</mat-icon>
    </div>
    <div fxFlex="85" fxFlex.lt-md="70" class="pl-12">
      <p class="font-size-16">{{userData?.role?.name}}</p>
    </div>
    <div fxFlex="15" fxFlex.lt-md="30" class="accent-bg p-12 text-center">
      <mat-icon class="white-color font-size-32">settings</mat-icon>
    </div>
    <div fxFlex="85" fxFlex.lt-md="70" class="pl-12">
      <p class="primary-color mb-8 hand-cursor" (click)="openUserModal('edit', userData)">
        <mat-icon class="ver-middle font-size-20" color="primary">edit</mat-icon>
        Edit your basic info
      </p>
      <p class="primary-color mt-4 hand-cursor" (click)="togglePassword()">
        <mat-icon class="ver-middle font-size-20" color="primary">vpn_key</mat-icon>
        Change your password
      </p>
    </div>
    <div fxFlex="100" class="pb-20 px-16" ngClass.gt-sm="px-32" *ngIf="changePassword">
      <p class="boulder-color">
        <mat-icon color="accent" fxFlex="10" fxFlex.lt-md="15">warning</mat-icon>
        <span fxFlex="90" fxFlex.lt-md="85">This action has major consequences on your access to the portal.<br/>Proceed only if you are 100%
          sure.</span></p>
      <form fxLayout="column" [formGroup]="resetPasswordForm">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>New Password</mat-label>
          <input matInput [type]="newPasswordVisibility ? 'text' : 'password'" formControlName="password">
          <mat-icon matSuffix class="font-size-16 boulder-color hand-cursor"
                    (click)="newPasswordVisibility = !newPasswordVisibility"
                    [matTooltip]="(newPasswordVisibility ? 'Hide Password' : 'Show Password')">
            {{ newPasswordVisibility ? 'visibility_off' : 'visibility' }}
          </mat-icon>
          <mat-error *ngIf="password.hasError('required') && password.touched">This field is required</mat-error>
          <mat-error *ngIf="password.hasError('min-length') && password.touched">Minimum length should be 8</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Confirm New Password</mat-label>
          <input matInput [type]="confirmPasswordVisibility ? 'text' : 'password'"
                 formControlName="password_confirmation">
          <mat-icon matSuffix class="font-size-16 boulder-color hand-cursor"
                    (click)="confirmPasswordVisibility = !confirmPasswordVisibility"
                    [matTooltip]="(confirmPasswordVisibility ? 'Hide Password' : 'Show Password')">
            {{ confirmPasswordVisibility ? 'visibility_off' : 'visibility' }}
          </mat-icon>
          <mat-error *ngIf="confirmPassword.hasError('required') && confirmPassword.touched">This field is required
          </mat-error>
          <mat-error *ngIf="confirmPassword.hasError('min-length') && confirmPassword.touched">Minimum length should be 8
          </mat-error>
          <mat-error *ngIf="confirmPassword.hasError('passwordMissMatch') && confirmPassword.touched">Password Mismatch
          </mat-error>
        </mat-form-field>
        <div fxLayout="row wrap" fxLayoutAlign="end end" fxLayoutGap="12px">
          <button mat-raised-button class="boulder-color" (click)="changePassword = false">Cancel</button>
          <button mat-raised-button color="warn" (click)="confirmPasswordChange()">Change</button>
        </div>
      </form>
    </div>
  </div>
</div>
