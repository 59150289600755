<mat-dialog-content class="modal-header">
  <h2 class="mt-0 mb-4 white-color full-width">Distribution Details
    <mat-icon class="s-32 ver-middle pull-right white-color hand-cursor" (click)="closeDialog(false)">close</mat-icon>
  </h2>
</mat-dialog-content>
<mat-dialog-content>
  <div class="p-16" *ngIf="data">
    <div fxLayout="row wrap" fxLayoutGap="24px">
      <div fxFlex="25" fxFlex.lt-md="100">
        <p class="font-size-14 mb-0 black-60">Accession Number</p>
        <p class="mt-0 primary-color font-size-24">{{data.unique_identifier}}</p>
      </div>
      <div fxFlex="25" fxFlex.lt-md="100" *ngIf="customerInfo">
        <p class="font-size-14 mb-0 black-60">Customer ID</p>
        <p class="mt-0 font-size-24">{{customerInfo.cust_id}}</p>
      </div>
    </div>
    <div class="p-16 mb-16 porcelain-gray-bg">
      <p class="font-size-20">{{getPackageType()}}</p>
      <div fxLayout="row wrap">
        <div fxFlex="30" fxFlex.lt-md="100">
          <p class="font-size-14 mb-0 black-60">Weight (grams)</p>
          <p class="mt-0">{{data.quantity}}</p>
          <p class="font-size-14 mb-0 black-60">Request Date</p>
          <p class="mt-0">{{data.requested_date | date: 'dd MMM yyyy'}}</p>
          <p class="font-size-14 mb-0 black-60">Supplied Date</p>
          <p class="mt-0">{{data.supplied_date | date: 'dd MMM yyyy'}}</p>
        </div>
        <div fxFlex="auto">
          <p class="font-size-14 mb-0 black-60">Location</p>
          <p class="mt-0">{{data.location}}</p>
          <p class="font-size-14 mb-0 black-60">Purpose</p>
          <p class="mt-0">{{data.purpose}}</p>
          <p class="font-size-14 mb-0 black-60">Remarks</p>
          <p class="mt-0">{{data.remarks}}</p>
        </div>
      </div>
      <hr/>
      <div fxLayout="row wrap" class="mt-16" *ngIf="customerInfo">
        <div class="mb-12" fxFlex="32" fxFlex.lt-md="100">
          <p class="font-size-14 my-0 black-60">Customer Name</p>
          <p class="my-0">{{customerInfo.name}}</p>
        </div>
        <div class="mb-12" fxFlex="32" fxFlex.lt-md="100">
          <p class="font-size-14 my-0 black-60">Designation</p>
          <p class="my-0">{{customerInfo.designation}}</p>
        </div>
        <div class="mb-12" fxFlex="32" fxFlex.lt-md="100">
          <p class="font-size-14 my-0 black-60">Organization</p>
          <p class="my-0">{{customerInfo.organisation}}</p>
        </div>
        <div class="mb-12" fxFlex="32" fxFlex.lt-md="100">
          <p class="font-size-14 my-0 black-60">Country</p>
          <p class="my-0">{{customerInfo.country}}</p>
        </div>
        <div class="mb-12" fxFlex="32" fxFlex.lt-md="100">
          <p class="font-size-14 my-0 black-60">Contact Number</p>
          <p class="my-0">{{customerInfo.contact_number || '-'}}</p>
        </div>
        <div class="mb-12" fxFlex="32" fxFlex.lt-md="100">
          <p class="font-size-14 my-0 black-60">Email</p>
          <p class="my-0">{{customerInfo.email || '-'}}</p>
        </div>
        <div class="mb-12" fxFlex="32" fxFlex.lt-md="100">
          <p class="font-size-14 my-0 black-60">User Status</p>
          <p class="my-0">{{customerInfo.status}}</p>
        </div>
        <div class="mb-12" fxFlex="auto">
          <p class="font-size-14 my-0 black-60">Address</p>
          <p class="my-0">{{customerInfo.address}}</p>
        </div>
      </div>
      <hr/>
      <div fxLayout="row wrap" fxLayoutAlign="space-between">
        <!--redirect to seed details -->
        <a (click)="goToSeedDetails(data)">View all seed details</a>
        <div class="text-right">
          <p class="font-size-14 mt-0 mb-4 black-60">{{data.creation_date | date: 'dd MMM yyyy'}}</p>
          <p class="font-size-14 my-0 black-60">{{data.creator_name}}</p>
        </div>
      </div>

    </div>
  </div>
</mat-dialog-content>
