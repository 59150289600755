import {InjectionToken} from '@angular/core';

export interface AppConstants {
    image: {
        default: string;
    };
    appKeys: {
        credentialsKey: string;
    };
  packageType: {
    [key: string]: string;
  };
}

export const GLOBAL_CONSTANTS: AppConstants = {
    image: {
        default: 'assets/images/default_news.png'
    },
    appKeys: {
        credentialsKey: 'credentials'
    },
    packageType: {
      active_collection: 'Active',
      duplicate: 'Duplicate',
      germination: 'Germination & Viability',
      regeneration: 'Multiplication & Regeneration',
      rest: 'Rest',
      characterization: 'Characterization'
    }
};

export let APP_CONSTANTS = new InjectionToken<AppConstants>('app.constants');
