<table mat-table [dataSource]="distributionSource" class="full-width position-relative">
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef fxHide.lt-md>S.I</th>
    <td mat-cell *matCellDef="let element" fxHide.lt-md> {{element.id}} </td>
  </ng-container>
  <ng-container matColumnDef="accNumber">
    <th mat-header-cell *matHeaderCellDef> Accession Number</th>
    <td mat-cell *matCellDef="let element"> {{element.unique_identifier}} </td>
  </ng-container>
  <ng-container matColumnDef="custID">
    <th mat-header-cell *matHeaderCellDef> Customer ID</th>
    <td mat-cell *matCellDef="let element"> {{element.cust_id}} </td>
  </ng-container>
  <ng-container matColumnDef="custName">
    <th mat-header-cell *matHeaderCellDef fxHide.lt-md> Customer Name</th>
    <td mat-cell *matCellDef="let element" fxHide.lt-md> {{element.customer_name}} </td>
  </ng-container>
  <ng-container matColumnDef="requestDate">
    <th mat-header-cell *matHeaderCellDef fxHide.lt-md> Requested Date</th>
    <td mat-cell *matCellDef="let element" fxHide.lt-md> {{element.requested_date | date}} </td>
  </ng-container>
  <ng-container matColumnDef="supliedDate">
    <th mat-header-cell *matHeaderCellDef fxHide.lt-md> Supplied Date</th>
    <td mat-cell *matCellDef="let element" fxHide.lt-md> {{element.supplied_date | date}} </td>
  </ng-container>
  <ng-container matColumnDef="packageType">
    <th mat-header-cell *matHeaderCellDef fxHide.lt-md> Packet Type</th>
    <td mat-cell *matCellDef="let element" fxHide.lt-md> {{getPackageType(element.package_type)}} </td>
  </ng-container>
  <ng-container matColumnDef="quantity">
    <th mat-header-cell *matHeaderCellDef> Weight (grams)</th>
    <td mat-cell *matCellDef="let element"> {{element.quantity}} </td>
  </ng-container>
  <ng-container matColumnDef="dateStored">
    <th mat-header-cell *matHeaderCellDef fxHide.lt-md> Date Stored</th>
    <td mat-cell *matCellDef="let element" fxHide.lt-md> {{element.date_stored}} </td>
  </ng-container>
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <mat-icon color="primary" class="hand-cursor mr-8" (click)="$event.stopPropagation();ediDistribution(element)">edit</mat-icon>
      <mat-icon color="warn" class="hand-cursor" (click)="$event.stopPropagation();deleteModal(element)">delete</mat-icon>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="hand-cursor"
      (click)="openDetailsModal(row)"></tr>
  <!-- if data not loaded -->
  <div class="table-loader position-relative" *ngIf="!distributionSource?.data.length">
    <app-loader></app-loader>
  </div>
</table>
<mat-paginator [pageSize]="10" [pageSizeOptions]="[10]" showFirstLastButtons></mat-paginator>
