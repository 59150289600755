import {Component, OnInit, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-common-modal',
  templateUrl: './common-modal.component.html',
  styleUrls: ['./common-modal.component.scss']
})
export class CommonModalComponent implements OnInit {

  modalContent = {
    header: 'Confirmation',
    desc: 'Are you sure?',
    actionBtn: 'Yes',
    actionBtnColor: 'primary',
    cancelBtn: 'No'
  };

  constructor(
    public dialogRef: MatDialogRef<CommonModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      header: string;
      desc: string;
      actionBtn: string;
      actionBtnColor: string;
      cancelBtn: string;
    }
  ) {
    if (data && data.header) {
      this.modalContent = data;
    }
  }

  ngOnInit() {
  }

  closeDialog(result?: boolean) {
    this.dialogRef.close(result);
  }

}
