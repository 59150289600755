import {Component, OnInit, Inject, Optional, OnDestroy} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserList} from '../../../shared/models/user';
import {SharedService} from '../../../shared/services/shared.service';
import {Helpers} from '../../../shared/helpers/helpers';
import {debounceTime, switchMap, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {PeopleService} from '../service/people.service';
import {SearchParam} from '../../../shared/models/search-param';
import {CustomValidators} from '../../../shared/helpers/validators';
import {NotificationService} from '../../../shared/services/notification.service';

@Component({
  selector: 'app-add-user-modal',
  templateUrl: './user-modal.component.html',
  styleUrls: ['./user-modal.component.scss']
})
export class UserModalComponent implements OnInit, OnDestroy {
  userForm: FormGroup;
  private unsubscribe: Subject<void> = new Subject();

  constructor(private fb: FormBuilder, private sharedService: SharedService, private dialogRef: MatDialogRef<UserModalComponent>,
              private notify: NotificationService, private peopleService: PeopleService,
              @Optional() @Inject(MAT_DIALOG_DATA) public data: { user: UserList, type: string }) {
  }

  ngOnInit() {
    this.userForm = this.fb.group(this.getFormByType());
    this.userForm.get('email').valueChanges.pipe(debounceTime(300), takeUntil(this.unsubscribe), switchMap(value =>
      this.peopleService.userList(new SearchParam(null, value)))).subscribe(response => {
      this.userForm.get('email').setValidators([Validators.required, Validators.email,
        CustomValidators.existingEmailValidator(!!response.users.length)]);
      this.userForm.get('email').updateValueAndValidity({onlySelf: true, emitEvent: false});
    });
  }

  getFormByType() {
    return {
      id: this.data.user?.id || undefined,
      email: [{value: this.data.user?.email, disabled: this.data.type === 'edit'},
        Validators.compose([Validators.required, Validators.email])],
      role_id: [{value: this.data.user?.role?.id, disabled: (this.data.type === 'edit' && this.data.user?.id === this.getUserId())},
        Validators.required],
      name: [this.data.user?.name, Validators.required],
      phone: [this.data.user?.phone],
      designation: [this.data.user?.designation],
    };
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  onSubmit() {
    Helpers.markAllFormFieldsAsTouched(this.userForm);
    if (this.userForm.valid) {
      if (this.data.type === 'add') {
        this.sharedService.sendInvitation(this.userForm.value).subscribe(response => this.dialogRef.close(true),
          ((error) => this.notify.error(error.error.message)));
      } else {
        this.sharedService.updateUser(this.userForm.value).subscribe(response => this.dialogRef.close(true));
      }
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  getUserId() {
    return this.sharedService.getLoginUserId();
  }
}
