import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {CustomSnackbarComponent} from '../components/custom-snackbar/custom-snackbar.component';

export declare type alertType = 'success' | 'info' | 'warning' | 'error' | 'question';

@Injectable({providedIn: 'root'})
export class NotificationService {
  private matIconType = {
    success: 'check_circle_outline',
    error: 'error_outline',
    info: 'info',
    warning: 'warning',
    question: 'live_help'
  };
  private snackBarConfig: MatSnackBarConfig;

  constructor(private snackBar: MatSnackBar) {
    this.snackBarConfig = new MatSnackBarConfig();
    this.snackBarConfig.verticalPosition = 'top';
    this.snackBarConfig.horizontalPosition = 'right';
    this.snackBarConfig.duration = 3000;
  }

  show(title: string, message: string | Array<string>, type: alertType = 'success') {
    if (message instanceof Array) {
      message = this.convertToHtml(message);
    }
    const snackBarConfig = Object.assign({}, this.snackBarConfig, {
      data: {
        title,
        message,
        icon_type: this.matIconType[type],
        icon_color: type + '-color'
      }, panelClass: 'snackbar-' + type
    });
    this.snackBar.openFromComponent(CustomSnackbarComponent, snackBarConfig);
  }

  success(message: string | Array<string>) {
    this.show('Success', message, 'success');
  }

  error(message: string | Array<string>) {
    this.show('Error', message, 'error');
  }

  warning(message: string | Array<string>) {
    this.show('Warning', message, 'warning');
  }

  info(message: string | Array<string>) {
    this.show('Info', message, 'info');
  }

  question(message: string | Array<string>) {
    this.show('Question', message, 'question');
  }

  convertToHtml(messages: Array<string>): string {
    const message = '';
    messages.forEach((msg, index) => msg += `<div>${index + 1}. ${msg}</div>`);
    return message;
  }

}

