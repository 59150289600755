import {ModuleWithProviders, NgModule} from '@angular/core';
import {CustomSnackbarComponent} from './components/custom-snackbar/custom-snackbar.component';
import {NotificationService} from './services/notification.service';
import {SnackBarDirective} from './directives/snackbar-error.directive';
import {CommonModalComponent} from './components/common-modal/common-modal.component';
import {DependencyModule} from './dependency.module';
import {ProfileComponent} from './components/profile/profile.component';
import {LoaderComponent} from './components/loader/loader.component';
import {DistributionTableComponent} from './components/distribution-table/distribution-table.component';
import {NumberDirective} from './directives/number.directive';
import {UserModalComponent} from '../main/people/user-modal/user-modal.component';
import { DocumentLoaderComponent } from './components/document-loader/document-loader.component';

@NgModule({
  declarations: [
    UserModalComponent,
    CustomSnackbarComponent, SnackBarDirective, CommonModalComponent, ProfileComponent,
    LoaderComponent, DistributionTableComponent, NumberDirective,
    DocumentLoaderComponent
  ],
  imports: [DependencyModule],
  exports: [DependencyModule, CustomSnackbarComponent, SnackBarDirective, CommonModalComponent,
    ProfileComponent, LoaderComponent, DistributionTableComponent, NumberDirective, DocumentLoaderComponent],
  entryComponents: [CustomSnackbarComponent, CommonModalComponent, UserModalComponent]
})
export class SharedModule {
  // noinspection JSUnusedGlobalSymbols
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
        NotificationService
      ]
    };
  }
}
