export class SearchParam {
  query: string;
  page: number;
  per_page: number;

  constructor(params?: SearchParam, query?: string) {
    this.query = query || '';
    this.per_page = 10;
    this.page = params && params.page ? params.page : 1;
  }
}

export class SeedParams extends SearchParam {
  crop_name: string;
  local_name: string;
  local_variety: string;
  status: string;
  type: string;
  classification: string;
  donor: string;
  dzongkhag: string;
  gewog: string;
  duration: string;
}

export class DistributionParams extends SearchParam {
  cust_id: string;
  customer_id: number;
  name: string;
  accession: string;
  organisation: string;
  country: string;
  status: string;
  package_type: string;
}
