import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {NotificationService} from '../../../shared/services/notification.service';
import {map} from 'rxjs/operators';
import {
  Characterization,
  Customer,
  DistributionInfo,
  DonorInfo,
  GeneBank,
  LocationOption,
  SeedStructure,
  TestInfo
} from '../../../shared/models/seeds';
import {Observable} from 'rxjs';
import {Meta} from '../../../shared/models/meta';
import {SeedParams} from '../../../shared/models/search-param';
import {Helpers} from '../../../shared/helpers/helpers';

const o2f = require('object-to-formdata');

@Injectable()
export class SeedService {
  constructor(private httpClient: HttpClient, private notify: NotificationService) {
  }

  fetchCommonInfo(key: string, autocomplete: boolean, name: string, query?: string) {
    const param = new HttpParams().set('name', name || '').set('query', query || '');
    return this.httpClient.get(`/${key + (autocomplete ? '/auto_complete' : '')}`, {params: param}).pipe(map((response) => {
      return response[key];
    }));
  }

  createSeed(data: SeedStructure, passport: boolean, donorInfo?: DonorInfo): Observable<SeedStructure> {
    const seedType = passport ? 'seed' : 'foreign_seed';
    return this.httpClient[`${data.id ? 'put' : 'post'}`](`${'/' + seedType + 's'}${data.id ? ('/' + data.id) : ''}`,
      {[seedType]: data, donor_info: donorInfo}).pipe(map((response) => {
      this.notify.success('Seed has been ' + [data.id ? 'updated' : 'created'] + ' ' + 'successfully');
      return response;
    }));
  }

  listSeeds(param: SeedParams): Observable<{ seeds: SeedStructure[], meta: Meta }> {
    const seedParam = Helpers.setQueryParams(param, ['query', 'type', 'local_name', 'local_variety_name', 'classification', 'donor_name',
      'status', 'crop_name', 'house_number', 'dzongkhag_id', 'gewog_id', 'maximum_altitude', 'minimum_altitude', 'requires_multiplication',
      'has_characterization', 'page', 'per_page']);
    return this.httpClient.get('/seeds', {params: seedParam}).pipe(map((response: { seeds: SeedStructure[], meta: Meta }) => {
      return response;
    }));
  }

  seedDetails(id: number, type: string): Observable<SeedStructure> {
    const seedType = type === 'ForeignSeed' ? 'foreign_seed' : 'seed';
    return this.httpClient.get(`/${seedType}s/${id}`).pipe(map((response) => {
      return response[seedType];
    }));
  }

  deleteSeed(id: number) {
    return this.httpClient.delete(`/seeds/${id}`).pipe(map(response => response));
  }

  createTestInfo(data: TestInfo) {
    return this.httpClient[`${data.id ? 'put' : 'post'}`](`/test_details/${data.id ? data.id : ''}`, {test_detail: data}).pipe(
      map((response) => {
        this.notify.success('Seed has been ' + [data.id ? 'updated' : 'created'] + ' ' + 'successfully');
        return response;
      }));
  }

  createGeneBank(data: GeneBank) {
    return this.httpClient[`${data.id ? 'put' : 'post'}`](`/gene_banks/${data.id ? data.id : ''}`, {gene_bank: data}).pipe(
      map((response) => {
        this.notify.success('Seed has been' + [data.id ? 'updated' : 'transferred'] + ' to Gene Bank Successfully');
        return response;
      }));
  }

  getGeneBank(geneId: number) {
    return this.httpClient.get(`/gene_banks/${geneId}`).pipe(
      map((response) => response));
  }

  downloadSeed(param: SeedParams): Observable<string> {
    return this.httpClient.post(`/seeds/export`, param).pipe(map((response: { blob: string }) => response.blob));
  }

  fetchCustomerInfo(key: string, name: string, query?: string) {
    const param = new HttpParams().set('name', name || '').set('query', query || '');
    return this.httpClient.get(`/${key}`, {params: param}).pipe(map((response) => {
      return response[key === 'customers/auto_complete' ? 'customers' : key];
    }));
  }

  createCustomer(data: Customer) {
    return this.httpClient[`${data.id ? 'put' : 'post'}`](`/customers/${data.id ? data.id : ''}`, {customer: data}).pipe(
      map((response: { customer: Customer }) => {
        // this.notify.success('Customer has been' + [data.id ? 'updated' : 'created'] +   ' Successfully');
        return response.customer;
      }));
  }

  getCustomerById(id: number): Observable<Customer> {
    return this.httpClient.get(`/customers/${id}`).pipe(map((response: {customer: Customer}) => response.customer));
  }

  deleteCustomer(customerID: number) {
    return this.httpClient.delete(`/customers/${customerID}`).pipe(map((response) => response));
  }

  createDistribution(data: DistributionInfo) {
    return this.httpClient[`${data.id ? 'put' : 'post'}`](`/distribution_infos/${data.id ? data.id : ''}`, {distribution_info: data}).pipe(
      map((response) => {
        this.notify.success('Seed has been' + [data.id ? 'updated' : 'distributed'] + ' to Gene Bank Successfully');
        return response;
      }));
  }

  getDistributionById(id: number): Observable<DistributionInfo> {
    return this.httpClient.get(`/distribution_infos/${id}`).pipe(map((response: {distribution_info; DistributionInfo}) =>
      response.distribution_info));
  }

  getDistributionList(seedId?: number): Observable<{ distribution_infos: DistributionInfo[], meta: Meta }> {
    const seedParam = new HttpParams().set('seed_id', seedId ? seedId.toString() : '');
    return this.httpClient.get(`/distribution_infos`, {params: seedParam}).pipe(
      map((response: { distribution_infos: DistributionInfo[], meta: Meta }) => response));
  }

  checkUniqueAccession(query: string): Observable<{ unique: boolean }> {
    const param = new HttpParams().set('query', query || '');
    return this.httpClient.get(`/gene_banks/unique_accession`, {params: param}).pipe(map((response: { unique: boolean }) => response));
  }

  disQualifySeed(seed: SeedStructure, disqualify: boolean) {
    const seedType = seed.type === 'ForeignSeed' ? 'foreign_seed' : 'seed';
    return this.httpClient.put(`${'/' + seedType + 's/'}${seed.id}`, {[seedType]: {banned: disqualify}}).pipe(map((response) => response));
  }

  getTemplate(type: string): Observable<ArrayBuffer> {
    const templateParams = new HttpParams().set('file', type);
    return this.httpClient.get(`/seeds/templates`, {params: templateParams, responseType: 'arraybuffer'}).pipe(
      map((response) => response));
  }

  uploadExcel(fileData: File, type: string): Observable<SeedStructure> {
    return this.httpClient.post(`/seeds/parse_excel`, o2f.serialize({
      file: fileData,
      name: `${type}_seed`
    }, {indices: true}, null, 'seed')).pipe(map((response: {seeds: Array<SeedStructure>}) => response.seeds[0]));
  }

  getDzongkhags(): Observable<Array<LocationOption>> {
    return this.httpClient.get(`/dzongkhags`).pipe(map((response: Array<LocationOption>) => response));
  }

  getGewogs(dzongkhagId: number): Observable<Array<LocationOption>> {
    const params = new HttpParams().set('dzongkhag_id', dzongkhagId.toString());
    return this.httpClient.get(`/gewogs`, {params}).pipe(map((response: Array<LocationOption>) => response));
  }

  createDonor(data: DonorInfo) {
    return this.httpClient[`${data.id ? 'put' : 'post'}`](`/donor_infos${data.id ? '/' + data.id : ''}`, {donor_info: data}).pipe(map(
      (response) => response));
  }

  characterizationSeed(data: Characterization) {
    return this.httpClient[`${data.id ? 'put' : 'post'}`](`/descriptions${data.id ? '/' + data.id : ''}`,
      o2f.serialize(data, {indices: true}, null, 'description')).pipe(map((response) => response));
  }

  deleteCharacterization(id: number) {
    return this.httpClient.delete(`/descriptions/${id}`).pipe(map((response) => response));
  }

  latestSeed(): Observable<{ id: number }> {
    return this.httpClient.get('/seeds/latest').pipe(map((response: { id: number }) => response));
  }
}
