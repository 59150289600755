import {Inject, Injectable} from '@angular/core';
import {NotificationService} from './notification.service';
import {APP_CONSTANTS, AppConstants} from '../models/constants';
import {UserRole} from '../enum/app-role.enum';
import {UserList} from '../models/user';
import {Credentials} from '../models/credentials';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private selectedModuleSubject = new BehaviorSubject(undefined);

  constructor(private notify: NotificationService, @Inject(APP_CONSTANTS) private constants: AppConstants,
              private httpClient: HttpClient) {
  }

  sendInvitation(user: Credentials) {
    return this.httpClient.post(`/users/invitation`, {user})
      .pipe(map(response => {
        this.notify.success('User Invitation Send Successfully');
      }));
  }

  updateUser(user: Credentials) {
    return this.httpClient.put(`/users/${user.id}`, {user})
      .pipe(map(response => {
        this.notify.success('User Updated Successfully');
      }));
  }

  getUserById(id: number): Observable<UserList> {
    return this.httpClient.get(`/users/${id}`).pipe(map((response: {user: UserList}) => response.user));
  }

  getCredential(): UserList {
    const savedCredentials = sessionStorage.getItem(this.constants.appKeys.credentialsKey)
      || localStorage.getItem(this.constants.appKeys.credentialsKey);
    return savedCredentials ? JSON.parse(savedCredentials) : null;
  }

  getLoginUserId(): number | null {
    return this.getCredential() ? this.getCredential().id : null;
  }

  getRoleId(): number | null {
    return this.getCredential() ? this.getCredential().role.id : null;
  }

  isAdmin(): boolean {
      return this.getRoleId() === UserRole.admin;
  }

  set selectedModule(value: string) {
    this.selectedModuleSubject.next(value);
  }

  get moduleSelected(): Observable<string> {
    return this.selectedModuleSubject.asObservable();
  }
}
