import {
  AfterViewInit,
  Component,
  EventEmitter, Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  ViewChild
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DistributionInfo } from '../../models/seeds';
import { MatPaginator } from '@angular/material/paginator';
import { Meta } from '../../models/meta';
import { MatDialog } from '@angular/material/dialog';
import { DistributionDetailsModalComponent } from '../../../main/distribution/distribution-details-modal/distribution-details-modal.component';
import { debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SearchParam } from '../../models/search-param';
import { CommonModalComponent } from '../common-modal/common-modal.component';
import { DistributionModalComponent } from '../../../main/seeds/seed-detail/distribution-modal/distribution-modal.component';
import {DistributionService} from '../../../main/distribution/service/distribution.service';
import { APP_CONSTANTS, AppConstants } from '../../models/constants';

@Component({
  selector: 'app-distribution-table',
  templateUrl: './distribution-table.component.html',
  styleUrls: ['./distribution-table.component.scss']
})
export class DistributionTableComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  displayedColumns = [
    'id',
    'accNumber',
    'custID',
    'custName',
    'requestDate',
    'supliedDate',
    'packageType',
    'quantity',
    'dateStored',
    'actions'];
  @Input() distributionSource: MatTableDataSource<DistributionInfo>;
  @Input() metaData: Meta;
  @Input() searchParam: SearchParam;
  @Output() pageChange = new EventEmitter<SearchParam>();
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  private unsubscribe: Subject<void> = new Subject();

  constructor(private dialog: MatDialog, private distributionService: DistributionService,
              @Inject(APP_CONSTANTS) private constants: AppConstants) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.paginator.page.pipe(distinctUntilChanged(), debounceTime(200), tap(() => {
      this.searchParam.page = this.paginator.pageIndex + 1;
    }), takeUntil(this.unsubscribe)).subscribe(() => this.pageChange.emit(this.searchParam));
  }

  ngOnChanges(changes: { distributionSource: SimpleChange, metaData: SimpleChange }) {
    // this.metaData = changes.metaData && changes.metaData.currentValue;
    this.setPagination();
  }

  setPagination() {
    this.paginator.length = this.metaData.total;
    this.paginator.pageIndex = this.metaData.current - 1;
  }

  openDetailsModal(distribution: DistributionInfo) {
    this.dialog.open(DistributionDetailsModalComponent, {width: '800px', autoFocus: false, data: distribution})
      .afterClosed().subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ediDistribution(distribution: DistributionInfo) {
    this.dialog.open(DistributionModalComponent, {
      width: '1000px', autoFocus: false,
      data: { distribution, seedId: distribution.seed_id}
    }).afterClosed().subscribe(result => {
      if (result) {
        this.pageChange.emit(this.searchParam);
      }
    });
  }

  deleteModal(distribution) {
    this.dialog.open(CommonModalComponent, {
      width: '500px',
      autoFocus: false,
      data: {
        header: 'Are you sure to delete this distribution?',
        desc: 'All the details will be deleted and the seed weights will be added back to the respective seeds.',
        actionBtn: 'Delete Anyway',
        actionBtnColor: 'warn',
        cancelBtn: 'Cancel'
      },
      panelClass: 'delete-modal'
    }).afterClosed().subscribe(result => {
      if (result) {
        this.distributionService.deleteDistribution(distribution.id).subscribe(() => this.pageChange.emit(this.searchParam));
      }
    });
  }

  getPackageType(type: string) {
    return this.constants.packageType[type];
  }

}
