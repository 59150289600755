import {AbstractControl, FormControl, ValidatorFn} from '@angular/forms';

class ValidatorClass {
  accessionValidator = (flag: boolean): ValidatorFn => {
    return (control: FormControl) => {
      if (!control.value) {
        return null;
      }
      return flag ? null : {accessionExists: true};
    };
  }

  confirmPassValidator = (input: AbstractControl): ValidatorFn => {
    return (control: FormControl) => {
      if (!control.value) {
        return null;
      }
      return input.value === control.value ? null : {passwordMissMatch: true};
    };
  }

  quantityValidator = (quantity: number): ValidatorFn => {
    return (control: FormControl) => {
      if (!control.value) {
        return null;
      }
      return (quantity - +control.value) >= 10 ? null : {invalidQty: true};
    };
  }

  existingEmailValidator = (exist: boolean): ValidatorFn => {
    return (control: FormControl) => {
      if (!control.value) {
        return null;
      }
      return exist ? {emailExist: true} : null;
    };
  }

}

export const CustomValidators = new ValidatorClass();
