import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {map} from 'rxjs/internal/operators';
import {Credentials} from '../../shared/models/credentials';
import {SharedService} from '../../shared/services/shared.service';
import {UserList} from '../../shared/models/user';
import {NotificationService} from '../../shared/services/notification.service';

const credentialsKey = 'credentials';

@Injectable()
export class AuthenticationService {

  constructor(private httpClient: HttpClient, private sharedService: SharedService,
              private notify: NotificationService) {
  }

  login(credentials: Credentials) {
    const data = {user: credentials};
    return this.httpClient
      .post(`/users/sign_in`, data, {observe: 'response'})
      .pipe(map((response: HttpResponse<any>) => {
          const user = {token: response.headers.get('authorization'), ...response.body.user};
          this.setCredentials(user, credentials.remember_me);
          return response.body;
        })
      );
  }

  resetPassword(data: Credentials, id: number) {
    return this.httpClient.put(`/users/${id}`, {user: data})
      .pipe(map(response => response));
  }

  forgotPassword(email: Credentials) {
    return this.httpClient.post(`/users/password`, {user: email})
      .pipe(map(response => response));
  }

  acceptInvitation(invitation: Credentials) {
    return this.httpClient.put(`/users/invitation`, {user: invitation})
      .pipe(map(response => this.notify.success('Invitation Accepted')));
  }


  logout(): Observable<boolean> {
    this.setCredentials();
    return of(true);
  }

  /**
   * Checks is the user is authenticated.
   * @return True if the user is authenticated.
   */
  isAuthenticated(): boolean {
    return !!this.sharedService.getCredential();
  }

  setCredentials(user?: UserList, remember?: boolean) {
    if (user) {
      const storage = remember ? localStorage : sessionStorage;
      storage.setItem(credentialsKey, JSON.stringify(user));
    } else {
      sessionStorage.removeItem(credentialsKey);
      localStorage.removeItem(credentialsKey);
    }
  }

  getAuthToken(url: string): string {
    let savedCredentials = sessionStorage.getItem(credentialsKey) || localStorage.getItem(credentialsKey);
    if (savedCredentials) {
      savedCredentials = JSON.parse(savedCredentials);
    }
    return !['sign_in', 'password'].includes(url) && savedCredentials && savedCredentials['token'] ? savedCredentials['token'] : '';
  }

}
