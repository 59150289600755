import {Component, Inject, OnInit, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Customer, DistributionInfo} from '../../../shared/models/seeds';
import {DistributionService} from '../service/distribution.service';
import {Router} from '@angular/router';
import { APP_CONSTANTS, AppConstants } from '../../../shared/models/constants';

@Component({
  selector: 'app-distribution-details-modal',
  templateUrl: './distribution-details-modal.component.html',
  styleUrls: ['./distribution-details-modal.component.scss']
})
export class DistributionDetailsModalComponent implements OnInit {
  customerInfo: Customer;

  constructor(private distributionService: DistributionService, private dialogRef: MatDialogRef<DistributionDetailsModalComponent>,
              @Optional() @Inject(MAT_DIALOG_DATA) public data: DistributionInfo, private router: Router,
              @Inject(APP_CONSTANTS) private constants: AppConstants) {
  }

  ngOnInit(): void {
    if (this.data) {
      this.distributionService.getCustomerDetails(this.data.customer_id).subscribe(response => this.customerInfo = response);
    }
  }

  getPackageType() {
    return this.constants.packageType[this.data.package_type];
  }

  closeDialog(value: boolean) {
    this.dialogRef.close(value);
  }

  goToSeedDetails(data: DistributionInfo) {
    this.closeDialog(false);
    this.router.navigate([`seeds/details/${data.seed_id}`], {replaceUrl: true, queryParams: {type: data.seed_type}});
  }

}
