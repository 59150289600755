import {Component, OnInit} from '@angular/core';
import {UserModalComponent} from '../../../main/people/user-modal/user-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {CommonModalComponent} from '../common-modal/common-modal.component';
import {UserList} from '../../models/user';
import {SharedService} from '../../services/shared.service';
import {AuthenticationService} from '../../../core/authentication/authentication.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NotificationService} from '../../services/notification.service';
import {PeopleService} from '../../../main/people/service/people.service';
import {CustomValidators} from '../../helpers/validators';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  providers: [PeopleService]
})
export class ProfileComponent implements OnInit {
  changePassword: boolean;
  userData: UserList;
  resetPasswordForm: FormGroup;
  oldPasswordVisibility: boolean;
  newPasswordVisibility: boolean;
  confirmPasswordVisibility: boolean;

  constructor(private dialog: MatDialog, private sharedService: SharedService,
              private authService: AuthenticationService, private fb: FormBuilder,
              private notify: NotificationService) {
  }

  ngOnInit() {
    this.initialize();
    this.resetPasswordForm = this.fb.group({
      password: [undefined, Validators.compose([Validators.required, Validators.minLength(8)])],
      password_confirmation: undefined
    });
    this.confirmPassword.setValidators(Validators.compose([Validators.required,
      Validators.minLength(8), CustomValidators.confirmPassValidator(this.password)]));
  }

  initialize() {
    this.sharedService.getUserById(this.userId()).subscribe(response => {
      this.userData = response;
    });
  }

  togglePassword() {
    this.changePassword = !this.changePassword;
    this.resetPasswordForm.reset();
  }

  openUserModal(type: string, user?: UserList) {
    const dialogRef = this.dialog.open(UserModalComponent, {
      width: '600px', autoFocus: false,
      data: {type, user}
    });
    dialogRef.afterClosed().pipe(v => v).subscribe(result => {
      if (result) {
        this.initialize();
      }
    });
  }

  confirmPasswordChange() {
    if (this.resetPasswordForm.valid) {
      const dialogRef = this.dialog.open(CommonModalComponent, {
        width: '500px',
        autoFocus: false,
        data: {
          header: 'Are you sure to change your password?',
          desc: `This action has major consequences on your access to the portal.<br/>Proceed only if you are 100% sure.`,
          actionBtn: 'Confirm',
          actionBtnColor: 'warn',
          cancelBtn: 'Cancel'
        },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.resetUserPassword();
        }
      });
    }
  }

  resetUserPassword() {
    this.authService.resetPassword(this.resetPasswordForm.value, this.userId()).subscribe(response => {
      this.notify.success('Password reset Successfully');
      this.togglePassword();
    });
  }

  userId() {
    return this.sharedService.getLoginUserId();
  }

  get password(): FormControl {
    return this.resetPasswordForm.get('password') as FormControl;
  }

  get confirmPassword(): FormControl {
    return this.resetPasswordForm.get('password_confirmation') as FormControl;
  }
}
