import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {AuthenticationGuard} from './authentication/authentication.guard';
import {APP_CONSTANTS, GLOBAL_CONSTANTS} from '../shared/models/constants';
import {SharedModule} from '../shared/shared.module';
import {AuthenticationService} from './authentication/authentication.service';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {ApiInterceptor} from './https/api.interceptor';

const appRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('../main/main.module').then(m => m.MainModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'auth',
    loadChildren: () => import('../auth/auth.module').then(m => m.AuthModule)
  },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forRoot(appRoutes)
  ],
  providers: [
    AuthenticationGuard,
    AuthenticationService,
    {
      provide: APP_CONSTANTS,
      useValue: GLOBAL_CONSTANTS
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    }
  ]
})
export class CoreModule {
}
