import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appSnackBarError]'
})
export class SnackBarDirective implements OnInit {
  @Input() appSnackBarError: object;
  native: any;

  constructor(private renderer: Renderer2, private elementRef: ElementRef) {
  }

  ngOnInit() {
    this.native = this.elementRef.nativeElement;
    this.errorParser(this.appSnackBarError);
  }

  errorParser(options) {
    Object.keys(options).forEach(key => {
      this.KeyParser(key);
      if (options[key] instanceof Array) {
        this.arrayParser(options[key]);
      } else {
        this.objectParser(options[key]);
      }
      this.native = this.native.parentNode;
    });
  }

  objectParser(keys) {
    const ul = this.renderer.createElement('ul');
    this.native.appendChild(ul);
    this.native = this.native.lastChild;
    this.errorParser(keys);
    this.native = this.native.parentNode;
  }

  arrayParser(keys) {
    const ul = this.renderer.createElement('ul');
    keys.forEach(result => {
      const li = this.renderer.createElement('li');
      this.renderer.addClass(li, 'msg-content');
      const text = this.renderer.createText(result);
      this.renderer.appendChild(li, text);
      this.renderer.appendChild(ul, li);
      this.native.appendChild(ul);
    });
  }

  KeyParser(msg) {
    const text = this.renderer.createText(msg);
    const li = this.renderer.createElement('li');
    this.renderer.appendChild(li, text);
    this.renderer.appendChild(this.native, li);
    this.native = this.native.lastChild;
  }
}
