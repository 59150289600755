import {Injectable, Injector} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from '../../../environments/environment';
import {AuthenticationService} from '../authentication/authentication.service';
import {catchError} from 'rxjs/internal/operators';
import {NotificationService} from '../../shared/services/notification.service';
import {Router} from '@angular/router';

@Injectable({providedIn: 'root'})
export class ApiInterceptor implements HttpInterceptor {

  constructor(private injector: Injector, private notify: NotificationService, private authService: AuthenticationService,
              private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!/^(http|https):/i.test(request.url)) {
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || '';
      const auth = this.injector.get(AuthenticationService);
      const httpHeaders = new HttpHeaders()
        .append('Authorization', request.url.includes('login') ? '' : auth.getAuthToken(request.url))
        .append('Accept-Language', 'EN')
        .append('X-Time-Zone', userTimeZone ? userTimeZone.toString() : '');

      request = request.clone({
        url: environment.serverUrl + request.url,
        headers: httpHeaders
      });
    }
    return next.handle(request).pipe(catchError((response: HttpErrorResponse) => {
      if (response.status === 0) {
        this.notify.error('Could not reach to server, Please check your Internet Connection');
      } else if (response.status === 401) {
        this.notify.error('Invalid Email or Password');
        this.authService.logout().subscribe(() => {
          this.router.navigate(['/auth'], {replaceUrl: true});
        });
      } else {
        this.notify.error(response.error.errors && response.error.errors.length ? response.error.errors[0] : response.error.error);
      }
      return throwError(response);
    }));
  }
}
