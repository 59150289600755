import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {NotificationService} from '../../../shared/services/notification.service';
import {Observable} from 'rxjs';
import {Customer, CustomerInfo, DistributionInfo, SeedStructure} from '../../../shared/models/seeds';
import {map} from 'rxjs/operators';
import {Meta} from '../../../shared/models/meta';
import {DistributionParams, SeedParams} from '../../../shared/models/search-param';
import {Helpers} from '../../../shared/helpers/helpers';

@Injectable()
export class DistributionService {

  constructor(private httpClient: HttpClient, private notify: NotificationService) {
  }

  getDistributionList(param: DistributionParams): Observable<{ distribution_infos: DistributionInfo[], meta: Meta }> {
    const distributionParam = Helpers.setQueryParams(param, ['query', 'type', 'page', 'per_page', 'customer_id', 'customer_name',
      'organisation', 'country', 'accession', 'status', 'package_type']);
    return this.httpClient.get(`/distribution_infos`, {params: distributionParam}).pipe(
      map((response: { distribution_infos: DistributionInfo[], meta: Meta }) => response));
  }

  listSeeds(param: SeedParams): Observable<SeedStructure[]> {
    const seedParam = Helpers.setQueryParams(param, ['query', 'type', 'page', 'per_page']);
    return this.httpClient.get('/seeds', {params: seedParam}).pipe(map((response: { seeds: SeedStructure[], meta: Meta }) => {
      return response.seeds;
    }));
  }

  seedDetails(id: number, type: string): Observable<SeedStructure> {
    const seedType = type === 'ForeignSeed' ? 'foreign_seed' : 'seed';
    return this.httpClient.get(`/${seedType}s/${id}`).pipe(map((response) => {
      return response[seedType];
    }));
  }

  getGeneBank(geneId: number) {
    return this.httpClient.get(`/gene_banks/${geneId}`).pipe(
      map((response) => response));
  }

  fetchCommonInfo(key: string, autocomplete: boolean, name: string, query?: string) {
    const param = new HttpParams().set('name', name || '').set('query', query || '').set('per_page', '15');
    return this.httpClient.get(`/${key + (autocomplete ? '/auto_complete' : '')}`, {params: param}).pipe(map((response) => {
      return response[key];
    }));
  }

  createCustomer(data: Customer): Observable<CustomerInfo> {
    return this.httpClient[`${data.id ? 'put' : 'post'}`](`/customers/${data.id ? data.id : ''}`, {customer: data}).pipe(
      map((response: { customer: CustomerInfo }) => {
        this.notify.success('Customer has been' + [data.id ? 'updated' : 'created'] +   ' Successfully');
        return response.customer;
      }));
  }

  createDistribution(data: DistributionInfo): Observable<DistributionInfo> {
    return this.httpClient[`${data.id ? 'put' : 'post'}`](`/distribution_infos/${data.id ? data.id : ''}`, {distribution_info: data}).pipe(
      map((response: {distribution_info: DistributionInfo}) => {
        this.notify.success('Seed has been' + [data.id ? 'updated' : 'distributed'] + ' to Gene Bank Successfully');
        return response.distribution_info;
      }));
  }

  getDistributionDetails(id: number): Observable<DistributionInfo> {
    return this.httpClient.get(`/distribution_infos/${id}`).pipe(map((response: {distribution_info: DistributionInfo}) => {
      return response.distribution_info;
    }));
  }

  getCustomerDetails(id: number): Observable<Customer> {
    return this.httpClient.get(`/customers/${id}`).pipe(map((response: {customer: Customer}) => {
      return response.customer;
    }));
  }

  deleteDistribution(id: number) {
    return this.httpClient.delete(`/distribution_infos/${id}`).pipe(map(response => {
      this.notify.success('Distribution has been deleted Successfully');
      return response;
    }));
  }

  downloadDistribution(param: DistributionParams): Observable<string> {
    const distributionParam = Helpers.setQueryParams(param, ['query', 'type', 'page', 'per_page', 'customer_id', 'name', 'organisation',
      'country', 'accession', 'status', 'package_type']);
    return this.httpClient.post(`/distribution_infos/export`, distributionParam).pipe(map(
      (response: { blob: string }) => response.blob));
  }
}
