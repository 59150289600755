import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {NotificationService} from '../../../shared/services/notification.service';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Meta} from '../../../shared/models/meta';
import {UserList} from '../../../shared/models/user';
import {SearchParam} from '../../../shared/models/search-param';

@Injectable({providedIn: 'root'})
export class PeopleService {
  constructor(private httpClient: HttpClient, private notify: NotificationService) {
  }

  userList(searchParam: SearchParam): Observable<{users: Array<UserList>, meta: Meta}> {
    const parameters = searchParam;
    const params = new HttpParams().set('page', parameters.page.toString())
      .set('per_page', parameters.per_page.toString())
      .set('query', parameters.query || '');
    return this.httpClient.get(`/users`, {params})
      .pipe(map((response: {users: Array<UserList>, meta: Meta}) => response));
  }

  getUserById(id: number) {
    return this.httpClient.get(`/users/${id}`).pipe(map(response => response));
  }

  softDeleteUser(id: number, deleteTime: string) {
    return this.httpClient.put(`/users/${id}/soft_delete`, {user: {deleted_at: deleteTime}})
      .pipe(map(response => this.notify.success('User has been Deleted Successfully')));
  }

}
