import { Meta } from './meta';

export const Collection = {
  collection_number: 'collectionNoOptions',
  mission_number: 'missionNoOptions',
  collection_source: 'sourceOptions',
  collectors_name: 'collectorOptions',
  collection_note: 'noteOptions',
  donor_name: 'donorNameOptions',
  house_number: 'houseNoOptions',
  dzongkhag: 'dzongkhagOptions',
  gewog: 'gewogOptions',
  dungkhag: 'dungkhagOptions',
  village: 'villageOptions',
  soil_color: 'soilColorOptions',
  soil_texture: 'soilTextureOptions',
  topography: 'topographyOptions',

  local_name: 'localNameOptions',
  local_variety_name: 'localVarietyOptions',
  sample_status: 'sampleStatusOptions',


  family: 'familyOptions',
  genus: 'genusOptions',
  species: 'speciesOptions',
  sub_texa: 'subTexaOptions',
  material_type: 'materialOptions',
  classification: 'classOptions',
  resistant: 'resistantOptions',
  susceptible: 'susceptibleOptions',

  nursery_month: 'nurseryOptions',
  planting_month: 'plantingOptions',
  harvesting_month: 'harvestingOptions',
  cultivation_practice: 'practiceOptions',
  crop_system: 'cropSysOptions',
  crop_name: 'cropNameOptions',


  condition: 'conditionOptions',
  donor_accession: 'donorAccessOptions',
  country: 'countryOptions',
  organisation: 'organisationOptions',
  web_url: 'webUrlOptions'
};


export const Distribution = {
  cust_id: 'customerIdOptions',
  customer_name: 'nameOptions',
  designation: 'designationOptions',
  organisation: 'organisationOptions',
  country: 'countryOptions',
  contact_number: 'contactOptions',
  email: 'emailOptions',
  status: 'statusOptions',
  address: 'addressOptions'
};

export interface CustomerInfo {
  id?: number;
  cust_id: string;
  name: string;
  designation: string;
  organisation: string;
  country: string;
  contact_number: string;
  email: string;
  status: string;
  address: string;
  creator_name?: string;
  creation_date?: string;
}

export interface CollectionInfo {
  mission_number: string;
  collection_number: string;
  collection_source: string;
  collectors_name: string;
  collection_note: string;
  collection_date?: string;
}

// export interface donorInfo {
//   latitude?: string;
//   longitude?: string;
//   altitude?: string;
//   soil_color: string;
//   soil_texture: string;
//   topography: string;
// }

export interface DonorInfo {
  id?: number;
  custom_donor_id: string;
  donor_name: string;
  house_number: string;
  dzongkhag_name: string;
  gewog_name: string;
  dungkhag: string;
  village: string;
  latitude?: string;
  longitude?: string;
  altitude?: string;
  soil_color: string;
  soil_texture: string;
  topography: string;
}

export interface SeedInfo {
  local_name: string;
  local_variety_name: string;
  sample_status: string;
}

export interface CultivationInfo {
  nursery_month: string;
  planting_month: string;
  harvesting_month: string;
  cultivation_practice: string;
  crop_system: string;
  characteristics: string;
  requires_multiplication: boolean;
}

export interface SeedStructure {
  id?: number;
  crop_name: string;
  family: string;
  genus: string;
  species: string;
  sub_texa: string;
  material_type: string;
  classification: string;
  resistant: string;
  susceptible: string;
  seed_status: string;
  unique_identifier?: string;
  donor_info_id?: number;
  collection_info?: CollectionInfo;
  donor_info?: DonorInfo;
  seed_info?: SeedInfo;
  cultivation_info?: CultivationInfo;
  repatriation_info?: RepatriationInfo;
  test_details: Array<TestInfo>;
  gene_bank: GeneBank;
  type: string | null;
  creation_date: string;
  creator_name: string;
  banned: boolean;
  description?: Characterization;
}

export interface RepatriationInfo {
  repatriation_number: string;
  repatriation_date: string;
  quantity: number;
  initial_germination_rate: number;
  local_name: string;
  local_variety_name: string;
  condition: string;
  donor_accession: string;
  donor_name: string;
  country: string;
  organisation: string;
  web_url: string;
  email_id: string;
  phone: string;
  fax: string;
  remarks: string;
}

export interface TestInfo {
  id?: number;
  seed_id: number;
  germination_rate: number;
  germination_date: string;
  moisture_content: number;
  moisture_date: string;
  date_stored?: string;
  remarks?: string;
  creation_date: string;
  creator_name: string;
}

export interface GeneBankAttribute {
  germination_weight?: number;
  germination_packets?: number;
  regeneration_weight?: number;
  regeneration_packets?: number;
  rest_weight?: number;
  rest_packets?: number;
  weight: number;
  packets: number;
  location: {
    name: string
  };
  locations: Array<{
    name: string
  }>;
  locations_attributes?: Array<{
    name: string
  }>;
  location_attributes?: {
    name: string;
  };
}

export interface GeneBank {
  id?: number;
  seed_id: number;
  seed_type?: string;
  accession_number: string;
  packaging_date: string;
  base_collection: GeneBankAttribute;
  base_collection_attributes?: GeneBankAttribute;
  active_collection: GeneBankAttribute;
  active_collection_attributes?: GeneBankAttribute;
  characterization: GeneBankAttribute;
  characterization_attributes?: GeneBankAttribute;
  duplicate: GeneBankAttribute;
  duplicate_attributes?: GeneBankAttribute;
  creator_name?: string;
  creation_date: Date;
}

export interface Customer {
  id?: number;
  name: string;
  designation: string;
  organisation: string;
  status: string;
  address: string;
  email: string;
  contact_number: string;
  country: string;
  creation_date: string;
  creator_name: string;
  cust_id: string;
}

export interface DistributionInfo {
  id?: number;
  customer_id: number;
  seed_id: number;
  seed_type: string;
  requested_date: string;
  supplied_date: string;
  package_type: string;
  quantity: number;
  purpose: string;
  remarks: string;
  date_stored?: string;
  location: string;
  unique_identifier?: string;
  creation_date: string;
  creator_name: string;
}

export interface LocationOption {
  id?: number;
  name: string;
}

export interface SeedDashboard {
  id: number;
  classification: string;
  seed_status: string;
  type: string;
  unique_identifier: string;
  creation_date: string;
  creator_name: string;
  crop_name: string;
  family: string;
}

export interface SeedMetas extends Meta {
  distributed_count: number;
  local_seed_count: number;
  cryo_count: number;
  rejected_count: number;
  repatriation_seed_count: number;
  tested_count: number;
  transferred_count: number;
  under_process_count: number;
}

export interface Characterization {
  id?: number;
  seed_id: number;
  passage: string;
  image: string;
  image_path?: string;
}
