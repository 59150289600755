<mat-dialog-content class="modal-header">
  <h2 class="mt-0 mb-4 white-color">Enter Distribution Details</h2>
</mat-dialog-content>
<mat-dialog-content>
  <div *ngIf="!data">
    loading...
  </div>
  <div *ngIf="!distributionAvailable() && data">
    Sorry, Distribution is Not Available for this seed.
  </div>
  <div class="p-16" *ngIf="distributionAvailable()">
    <form [formGroup]="customerForm">
      <h2>Customer Info</h2>
      <div fxLayout="column" fxLayoutAlign="space-between">
        <mat-checkbox class="mb-24" formControlName="existing" (change)="checkExisting($event)">Existing Customer?</mat-checkbox>
        <mat-form-field appearance="outline" class="half-width" *ngIf="customer('existing').value">
          <mat-label>Customer ID</mat-label>
          <input matInput [matAutocomplete]="customerID" formControlName="cust_id">
          <mat-autocomplete #customerID="matAutocomplete" [displayWith]="displayCustomerID">
            <mat-option *ngFor="let option of customerIdOptions | async"
                        [value]="option" (onSelectionChange)="customerSelect($event)">{{option.cust_id}}</mat-option>
          </mat-autocomplete>
          <mat-error>This field is required</mat-error>
        </mat-form-field>
        <div *ngIf="selectedCustomer" fxLayout="column" fxLayoutAlign="space-between" class="p-8 primary-border">
          <h2 class="m-4">Name: {{selectedCustomer?.name}}</h2>
          <p class="m-4">Designation: {{selectedCustomer?.designation || '-'}}</p>
          <p class="m-4">Address: {{selectedCustomer?.address || '-'}}</p>
        </div>
      </div>
      <div fxLayout="row wrap" fxLayoutGap="20px" *ngIf="!customer('existing').value">
        <mat-form-field appearance="outline" fxFlex="30" fxFlex.lt-md="100">
          <mat-label>Customer Name</mat-label>
          <input matInput [matAutocomplete]="customerName" formControlName="name">
          <mat-autocomplete #customerName="matAutocomplete">
            <mat-option *ngFor="let option of nameOptions | async"
                        [value]="option.name">{{option.name}}</mat-option>
          </mat-autocomplete>
          <mat-error>This field is required</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="30" fxFlex.lt-md="100">
          <mat-label>Designation</mat-label>
          <input matInput [matAutocomplete]="designation" formControlName="designation">
          <mat-autocomplete #designation="matAutocomplete">
            <mat-option *ngFor="let option of designationOptions | async"
                        [value]="option.designation">{{option.designation}}</mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="30" fxFlex.lt-md="100">
          <mat-label>Organization</mat-label>
          <input matInput [matAutocomplete]="organization" formControlName="organisation">
          <mat-autocomplete #organization="matAutocomplete">
            <mat-option *ngFor="let option of organisationOptions | async"
                        [value]="option.organisation">{{option.organisation}}</mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="30" fxFlex.lt-md="100">
          <mat-label>Country</mat-label>
          <input matInput [matAutocomplete]="country" formControlName="country">
          <mat-autocomplete #country="matAutocomplete">
            <mat-option *ngFor="let option of countryOptions | async"
                        [value]="option.country">{{option.country}}</mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="30" fxFlex.lt-md="100">
          <mat-label>Contact Number</mat-label>
          <input matInput [matAutocomplete]="contact" formControlName="contact_number">
          <mat-autocomplete #contact="matAutocomplete">
            <mat-option *ngFor="let option of contactOptions | async"
                        [value]="option.contact_number">{{option.contact_number}}</mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="30" fxFlex.lt-md="100">
          <mat-label>Email</mat-label>
          <input matInput type="email" [matAutocomplete]="email" formControlName="email">
          <mat-autocomplete #email="matAutocomplete">
            <mat-option *ngFor="let option of emailOptions | async"
                        [value]="option.email">{{option.email}}</mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="customerForm.get('email').hasError('email') && customerForm.get('email').touched">
            Please enter a valid email address
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="30" fxFlex.lt-md="100">
          <mat-label>User Status</mat-label>
          <input matInput [matAutocomplete]="userStatus" formControlName="status">
          <mat-autocomplete #userStatus="matAutocomplete">
            <mat-option *ngFor="let option of statusOptions | async"
                        [value]="option.status">{{option.status}}</mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="30" fxFlex.lt-md="100">
          <mat-label>Address</mat-label>
          <textarea matInput [matAutocomplete]="address" formControlName="address"></textarea>
          <mat-autocomplete #address="matAutocomplete">
            <mat-option *ngFor="let option of addressOptions | async"
                        [value]="option.address">{{option.address}}</mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </form>
    <form [formGroup]="distributionForm">
      <hr/>
      <h2>Distribution Info</h2>
      <div fxLayout="row wrap" fxLayoutGap="20px">
        <mat-form-field appearance="outline" fxFlex="30" fxFlex.lt-md="100">
          <mat-label>Packet Type</mat-label>
          <mat-select formControlName="package_type" (selectionChange)="onTypeChange($event)">
            <ng-container *ngFor="let pack of packageType">
              <mat-option *ngIf="checkPackWeight(pack.weight)" [value]="pack.type">{{pack.label}}
                (<span class="primary-color">{{pack.weight}}grams</span>)</mat-option>
            </ng-container>
          </mat-select>
          <mat-error>This field is required</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="30" fxFlex.lt-md="100">
          <mat-label>Weight (grams)</mat-label>
          <input matInput appNumber formControlName="quantity">
          <mat-error *ngIf="distributionForm.get('quantity').hasError('required') && distributionForm.get('quantity').touched">
            This field is required</mat-error>
          <mat-error *ngIf="distributionForm.get('quantity').hasError('invalidQty') && distributionForm.get('quantity').touched">
            Weight Exceeded</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="30" fxFlex.lt-md="100">
          <mat-label>Location</mat-label>
          <mat-select formControlName="location">
            <ng-container *ngFor="let option of locationList">
              <mat-option *ngIf="!!option" [value]="option" >{{option}}</mat-option>
            </ng-container>
          </mat-select>
          <mat-error>This field is required</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="30" fxFlex.lt-md="100">
          <mat-label>Request Date</mat-label>
          <input matInput [matDatepicker]="requestDate" formControlName="requested_date">
          <mat-datepicker-toggle matSuffix [for]="requestDate"></mat-datepicker-toggle>
          <mat-datepicker #requestDate></mat-datepicker>
          <mat-error>This field is required</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="30" fxFlex.lt-md="100">
          <mat-label>Supplied Date</mat-label>
          <input matInput [matDatepicker]="suppliedDate" formControlName="supplied_date">
          <mat-datepicker-toggle matSuffix [for]="suppliedDate"></mat-datepicker-toggle>
          <mat-datepicker #suppliedDate></mat-datepicker>
          <mat-error>This field is required</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="30" fxFlex.lt-md="100">
          <mat-label>Purpose</mat-label>
          <textarea matInput [matAutocomplete]="purpose" formControlName="purpose"></textarea>
          <mat-autocomplete #purpose="matAutocomplete">
            <mat-option *ngFor="let option of purposeOptions | async"
                        [value]="option.purpose">{{option?.purpose}}</mat-option>
          </mat-autocomplete>
          <mat-error>This field is required</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="30" fxFlex.lt-md="100">
          <mat-label>Remarks</mat-label>
          <textarea matInput formControlName="remarks"></textarea>
        </mat-form-field>
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions ngClass.gt-xs="pull-right" fxLayout.xs="column-reverse" fxLayoutGap="12px">
  <button type="button" class="primary-color silver-border" fxFlex.lt-md="100" ngClass.xs="ml-0 full-width"
          mat-button (click)="closeDialog(false)">
    Cancel
  </button>
  <button type="button" mat-raised-button color="primary" ngClass.xs="ml-0 my-20 full-width" fxFlex.lt-md="100"
          (click)="onSubmit()">
    <mat-icon class="s-20 mr-4">add_shopping_cart</mat-icon>
    {{ isUpdate() ? 'Update' : 'Distribute'}}
  </button>
</mat-dialog-actions>
